<template>
    <div>
        <vs-dialog width="550px" not-center v-model="editActive">
            <template #header>
            <h4 class="not-margin">
                <b>Edit note</b>
            </h4>
            </template>
            <vs-input @keypress.enter="update_note" v-if="editProp == 'note'" v-model="edit_note" />
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="modal.tags">
            <template #header>
            <h4 class="not-margin">
                <b>Add tag</b>
                <base-input @keypress.enter="update_tags" v-model="edit.tags_text" />
            </h4>
            </template>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="viewContent">
            <div>
                {{ edit.title }}
            </div>
            <br>
            <div>
                {{ edit.Description }}
            </div>
            <br>
            <div>
                {{ edit.keyword }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point1 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point2 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point3 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point4 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point5 }}
            </div>
        </vs-dialog>
        <vs-dialog width="600px" v-model="editing">
            <h3>Edit</h3>
            <hr>
            <vs-input v-model="edit.title" label-placeholder="Title">
                {{ edit.title }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.Description" label-placeholder="Description">
                {{ edit.Description }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.keyword" label-placeholder="Keyword">
                {{ edit.keyword }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point1" label-placeholder="bullet_point1">
                {{ edit.bullet_point1 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point2" label-placeholder="bullet_point2">
                {{ edit.bullet_point2 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point3" label-placeholder="bullet_point3">
                {{ edit.bullet_point3 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point4" label-placeholder="bullet_point4">
                {{ edit.bullet_point4 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point5" label-placeholder="bullet_point5">
                {{ edit.bullet_point5 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_1" label-placeholder="kw_ppc_1">
                {{ edit.kw_ppc_1 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_2" label-placeholder="kw_ppc_2">
                {{ edit.kw_ppc_2 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_3" label-placeholder="kw_ppc_3">
                {{ edit.kw_ppc_3 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_4" label-placeholder="kw_ppc_4">
                {{ edit.kw_ppc_4 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_5" label-placeholder="kw_ppc_5">
                {{ edit.kw_ppc_5 }}
            </vs-input>
            <hr>
            <vs-button
                success
                gradient
                @click="update_product"
            >
                confirm
            </vs-button>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="delete_confirm">
            <template #header>
            <h4 class="not-margin">
                <b>Do you want delete it {{ edit.asin }}?</b>
            </h4>
            </template>

            <template #footer>
            <div>
                <vs-button @click="delete_asin" transparent>
                    Ok
                </vs-button>
                <vs-button @click="delete_confirm = false" dark transparent>
                    Cancel
                </vs-button>
            </div>
            </template>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Mỗi lượt up từng file cho từng asin</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload_content">
          <b-container fluid="">
            <h3 align="center">Upload content</h3>
            <vue2Dropzone id="dropzonecontent" :options="dropzonecontent"></vue2Dropzone>
          </b-container>
        </vs-dialog>
        <b-container fluid>
            <b-card>
                <b-row>
                    <b-col xl="2">
                        <vs-button
                            @click="upload_metadata"
                            color="danger"
                            icon
                        >
                           Upload content
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-select placeholder="Niche" v-model="niche">
                            <vs-option label="Redesign" value="redegisn">
                                Redesign
                            </vs-option>
                            <vs-option label="Hijack" value="hijack">
                                Hijack
                            </vs-option>
                        </vs-select>
                    </b-col>
                    <b-col>
                        <vs-select placeholder="Filter product" v-model="filter_product">
                            <vs-option value="poster">
                                Poster
                            </vs-option>
                            <vs-option value="wall_art">
                                Wall art
                            </vs-option>
                            <vs-option value="mug">
                                Mug
                            </vs-option>
                            <vs-option value="phone-case">
                                Phone case
                            </vs-option>
                        </vs-select>
                    </b-col>
                    <b-col>
                        <vs-select placeholder="Employee" v-model="selected_employee">
                            <vs-option value="all">
                                all
                            </vs-option>
                            <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                            {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col>
                    <b-col>
                        <vs-select placeholder="Select template" v-model="selected_template">
                            <!-- <vs-option label="Poster Ngang" value="square_poster">
                                Poster Ngang
                            </vs-option> -->
                            <vs-option label="Wall Art Wood" value="wallart_wood">
                                Wall Art Wood
                            </vs-option>
                            <vs-option label="Wall Art Wood" value="wallart_black">
                                Wall Art Black
                            </vs-option>
                            <vs-option label="Mug" value="mug">
                                Mug
                            </vs-option>
                            <vs-option label="Canvas" value="canvas">
                                Poster + Canvas Dọc
                            </vs-option>
                            <vs-option label="Canvas Ngang" value="canvas_horizontal">
                                Poster + Canvas Ngang
                            </vs-option>
                            <!-- <vs-option label="Ornamets" value="ornamets">
                                Ornamets
                            </vs-option> -->
                        </vs-select>
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="export_csv"
                            color="danger"
                            icon
                            :loading="exporting"
                        >
                           Export
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_asin" v-model="search" placeholder="Search sku" />
                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_tags" v-model="tag_search" placeholder="Search by tags" />
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="download_mockup"
                            color="danger"
                            icon
                            :loading="exporting"
                            v-if="exported"
                        >
                           Download mockup
                        </vs-button>
                    </b-col>
                    <hr>
                    <b-col xl="12">
                        <vs-table>
                            <template #thead>
                            <vs-tr>
                                <vs-th class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="check_all"
                                        >
                                    </b-form-checkbox>
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Product
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Image
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Tags
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Note
                                </vs-th>
                                <vs-th class="vs-td-lg">
                                    Content
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                Employee
                                </vs-th>
                            </vs-tr>
                            </template>
                            <template #tbody>
                            <vs-tr
                                :key="i"
                                v-for="(tr, i) in asins"
                                :data="tr"
                            >
                                <vs-td class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="tr.selected"
                                        >
                                    </b-form-checkbox>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <strong>{{ tr.type }}</strong>
                                    <vs-button
                                        danger
                                        gradient
                                        @click="edit = tr, delete_confirm = true"
                                        icon
                                    >
                                        <i class='bx bx-trash-alt' ></i>
                                    </vs-button>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <div>
                                        <router-link  target="_blank" :to="{path: 'designs', query: {sku: tr.asin}}">
                                            <img style="width: 100px; height: auto" :src="tr.url" alt="">
                                        </router-link>
                                        <a v-if="tr.is_amazon == true" :href="`https://www.amazon.com/gp/product/${tr.origin_asin}`" target="_blank">
                                            <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                        </a>
                                        <div v-else-if="tr.origin_url">
                                            <a :href="tr.origin_url" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <a :href="tr.secondary_url" target="_blank">
                                                <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                            </a>
                                        </div>
                                    </div>
                                </vs-td>
                                <vs-td @click="edit=tr, modal.tags = !modal.tags" class="vs-td-sx">
                                    <div v-for="tag in tr.tags" :key="tag" style="margin: 10px 10px 10px 10px;">
                                        <span @click="doCopy(tag)" class="tags">{{ tag }}</span>
                                    </div>
                                </vs-td>
                                <vs-td edit @click="edit = tr, editActive = true, edit_note = edit.note, editProp = 'note'" class='vs-td-sx'>
                                    <strong @click="doCopy(tr.note)">{{ tr.note }}</strong>
                                </vs-td>
                                <vs-td class="vs-td-lg">
                                    <b-row>
                                        <b-col xl="11" :class="{'a-green': tr.Description}">
                                            <p @click="edit = tr, viewContent = !viewContent">
                                                {{ tr.title }}
                                            </p>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                warn
                                                gradient
                                                @click="editing = !editing, edit = tr"
                                                icon
                                            >
                                                <i class='bx bxs-edit-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_redesign(tr)"
                                                icon
                                            >
                                                <i class='bx bx-left-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_exported(tr)"
                                                icon
                                            >
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                    </b-row>
                                </vs-td>
                                <vs-td>
                                    <strong>{{ tr.employee }}</strong>
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                    </b-col>
                <hr>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
import Products from '../../../components/Products.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import XLSX from 'xlsx';
export default {
  name: "redesign",
  components: {
    Products,
    vue2Dropzone
  },
    data: () => ({
        modal: {tags: false},
        tag_search: '',
        images: [],
        all: [],
        filter_product: 'poster',
        data: [],
        search: '',
        edit_note: '',
        exported: false,
        viewContent: false,
        exporting: false,
        header: [],
        editing: false,
        selected_employee: '',
        selected_template: '',
        check_all: false,
        scopes: ['mod', 'admin'],
        upload_content: false,
        asin: '',
        niche: '',
        employees: [],
        editActive: false,
        editProp: '',
        asins: [],
        ads: [],
        delete_confirm: false,
        edit: Object,
        downloading: false,
        allow_dropzone: false,
        upload: false,
        dropzoneOptions: {
            url: `https://v2.fecom.io/designs`,
            // url: `http://0.0.0.0:5001/designs`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        dropzonecontent: {
            url: `https://v2.fecom.io/redesign/upload_content`,
            // url: `http://0.0.0.0:5001/redesign/upload_content`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            maxFiles: 1,
        },

    }),
    methods: {
        search_tags: function () {
            this.$vs.notification({
                'title': 'Searching tag',
                'color': 'warning'
            })
            fsg.get(`/redesign?exported=oke&tags=${this.tag_search}`)
                .then(r => { this.asins = r.data.msg; if (this.role == 'crawler'){this.asins = this.asins.filter(e => e.employee_id == this.employee_id)}})
        },
        update_tags: function () {
            this.modal.tags = false
            fsg.post("/product_tags", {'tags': this.edit.tags_text.split(","), 'sku': this.edit.asin})
                .then(r => {
                    this.$vs.notification({
                        title: "Updated",
                        color: "warning"
                    })
                    this.edit.tags = this.edit.tags_text.split(",")
                })
        },
        push_to_redesign: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_redesign=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        push_to_exported: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_exported=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        search_asin: function(){
            fsg.get(`redesign?asin=${this.search}`)
                .then(r => {this.asins = r.data.msg})
        },
        download_image: function(url, fn) {
            fetch(url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = fn;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        download_mockup: function() {
            this.exporting = true
            if (this.selected_template == 'ornamets'){
                let selected = this.asins.filter((as) => as.selected == true)
                var data = []
                selected.forEach(as => data.push(as.asin))
                fsg.post('/ornament_redesign?only_image=true', {'asins': data, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false, alert(`${this.images}`)})
            }else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')){
                fsg.post('/poster_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            } else if (this.selected_template == 'mug') {
                fsg.post('/mug_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            } else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)) {
                fsg.post('/canvas_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            }
        },
        download: function (){
            if(this.selected_template == 'ornaments'){
                const data = XLSX.utils.aoa_to_sheet(this.data)
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()

                XLSX.utils.book_append_sheet(wb, data, 'Sheet')
                XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.writeFile(wb, `ornaments.xlsx`)
            }
            else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')) {
                const hello = XLSX.utils.json_to_sheet(this.data, { header: this.header })
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'poster.xlsx')
                this.data = null
            }
            else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)){
                const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                // XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'canvas.xlsx')
                this.data = null

            } else {
                const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
                const ads = XLSX.utils.aoa_to_sheet(this.ads)
                const wb = XLSX.utils.book_new()
                // XLSX.utils.book_append_sheet(wb, ads, 'ads')
                XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
                XLSX.writeFile(wb, 'mug.xlsx')
                this.data = null
            }
        },
        export_csv: function(){
            this.exporting = true
            if (this.selected_template == 'ornamets'){
                let selected = this.asins.filter((as) => as.selected == true)
                var data = []
                selected.forEach(as => data.push(as.asin))
                fsg.post('/ornament_redesign', {'asins': data, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.exporting = false, this.ads = r.data.ads})
            }else if (this.selected_template.includes('poster') || this.selected_template.includes('wallart')){
                fsg.post('/poster_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            } else if (this.selected_template == 'mug') {
                fsg.post('/mug_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            } else if (['canvas', 'canvas_horizontal'].includes(this.selected_template)) {
                fsg.post('/canvas_redesign', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false})
            }
        },
        upload_metadata: function(){
            this.upload_content = !this.upload_content
        },
        update_product: function(){
            fsg.put('/update_product', this.edit)
                .then(r => {
                    this.$vs.notification({
                        title: "updated",
                        color: "success"
                    })
                })
        },
        add_asin: function(){
            this.downloading = true
            fsg.post(`/redesign?asin=${this.asin}`)
                .then(r => {
                    this.asins.unshift.apply(this.asins, r.data.msg),
                    this.downloading = false
                    this.upload = false
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        update_dropzone: function (val){
            this.dropzoneOptions.url = `https://v2.fecom.io/designs?type=${val}&hijack=oke`
            // this.dropzoneOptions.url = `http://0.0.0.0:5001/designs?type=${val}`
            this.allow_dropzone = !this.allow_dropzone
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        delete_asin: function(){
            fsg.delete(`/redesign?asin=${this.edit.asin}`)
                .then(r => {
                    this.delete_confirm = !this.delete_confirm
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })
        },
        update_note: function(){
            this.edit.note = this.edit_note
            fsg.put(`/redesign?asin=${this.edit.asin}&note=${this.edit.note}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Note updated',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Note update failed',
                        color: 'success'
                    })
                })
        }
    },
    beforeDestroy() {
        this.sockets.unsubscribe('confirm_design')
    },
    created() {
        this.sockets.subscribe('confirm_design', (asins) => {
            this.asins= this.asins.concat(asins)
            asins.forEach((as) => {
                this.$vs.notification({
                title: `New ${as.asin} - ${as.type}`,
                color: 'success',
                duration: 10000,
                progress: 'auto',
                })
            })
        })
        this.sockets.subscribe('assign_contenter', (data) => {
            if (localStorage.getItem('id') == data.id){
                this.$vs.notification({
                    title: `Vừa được gán mấy design mới kìa, vào làm viết content đê`,
                    text: data.text,
                    color: 'danger',
                    duration: 10000,
                    progress: 'auto',
                })
            }
        })
        fsg.get('/redesign?is_uploaded=oke&_type=blanket')
            .then(r => {this.all = r.data.msg, this.asins = this.all})
        fsg.get('/employees?team=content')
            .then(r => {this.employees = r.data.message})
    },
    watch: {
        filter_product: function() {
            if (this.filter_product == 'poster'){
                this.asins = this.all.filter(e => e.is_wallart == false && e.type == 'poster')
            } else if (this.filter_product == 'mug') {
                this.asins = this.all.filter(e => e.type == 'mug')
            }
            else if (this.filter_product == 'wall_art') {
                this.asins = this.all.filter(e => e.is_wallart == true && e.type == 'poster')
            }
            else {
                this.asins = this.all.filter(e => e.type == this.filter_product)
            }
        },
        niche: function () {
            if (this.niche == 'hijack') {
                fsg.get(`/redesign?niche=${this.niche}`)
                    .then(r => {this.all = r.data.msg, this.asins = this.all})
            } else {
            fsg.get('/redesign?is_uploaded=oke')
                .then(r => {this.all = r.data.msg, this.asins = this.all.filter(e => e.type == this.filter_product)})
            }
        },
        check_all: function(){
            this.asins.forEach(e => e.selected = !e.selected)
        },
        data: function(){
            this.download()
        },
        images: function(){
            this.images.forEach(obj => this.download_image(obj.url, obj.name))
        },
        selected_employee: function(){
            if (this.selected_employee == 'all'){
                fsg.get('/redesign?exported=oke')
                    .then(r => {this.asins = r.data.msg})
            }
            let selected = this.asins.filter((e) => e.selected == true)
            let data = []
            selected.forEach(e => data.push(e.asin))
            if (!data.length == 0){
                fsg.put(`/redesign?action=update_employee&content=true`, {'asins': data, 'employee': this.selected_employee})
                    .then(r => {
                        this.$vs.notification({
                            title: 'Updated employee',
                            color: 'success'
                        })
                    })
            }else {
                this.asins = this.asins.filter((e) => e.employee == this.selected_employee)
            }
        },
        exported: function(){
            if (this.exported) {
                fsg.get('/redesign?exported=oke')
                    .then(r => {this.asins = r.data.msg})
            }else {
                fsg.get('/redesign?is_uploaded=oke')
                    .then(r => {this.asins = r.data.msg})
            }
        }
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.tags {
    background-color: #ffd600;
    padding: 2px 5px 2px 5px;
    border-radius: 2px;
    box-shadow: #777;
    border: 1px;
    margin-block: 5px;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 5%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 10%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
