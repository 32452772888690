<template>
    <div>
        <!-- <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid>
            <h3 align="center">Upload design</h3>
            <div>
              <p style="color: darkred">Upload file proxies</p>
              <hr size="3">
              <vue2Dropzone ref="myVueDropzone" @vdropzone-sending="vdropzone_sending" id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
              <hr>
              <b-button @click="ddos" >Process</b-button>
            </div>
          </b-container>
        </vs-dialog> -->
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="min-height: 388px; background-size: 1700px 388px; background-position:   center center;">
        <b-row>
            <b-col>
                <stats-card title="Current Day"
                            type="gradient-red"
                            :sub-title="current_day.toString()"
                            icon="ni ni-spaceship"
                            class="mb-4">

                    <template slot="footer">
                    </template>
                </stats-card>
                <stats-card title="Total click"
                            type="gradient-red"
                            :sub-title="total.toString()"
                            icon="ni ni-spaceship"
                            class="mb-4">

                    <template slot="footer">
                    </template>
                </stats-card>
            </b-col>
            <b-col>
                <b-list-group>
                    <b-list-group-item v-for="kw in keywords" :key="kw.keyword" class="d-flex justify-content-between align-items-center">
                        {{ kw.keyword }}
                        <b-badge variant="primary" pill>{{kw.total_click}}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>

        <b-row class="form-group">
            <label for="keyword" class="col-md-2 col-form-label form-control-label">Keyword</label>
            <b-col md="12">
                <b-input v-model="keyword" placeholder="Keyword" id="keyword"></b-input>
            </b-col>
        </b-row>
       <b-row class="form-group">
            <label for="repeat" class="col-md-2 col-form-label form-control-label">Repate</label>
            <b-col md="12">
                <b-input v-model="repeat" placeholder="repate" id="repate"></b-input>
            </b-col>
        </b-row>
        <b-row class="form-group">
            <b-col md="12">
                <b-button @click="ddos" >Process</b-button>
            </b-col>
        </b-row>
        </base-header>
        <b-container fluid class="mt--7">
            <b-card no-body>
                <b-card-header class="border-0">
                </b-card-header>

                <el-table class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="asins">
                    <el-table-column label="created"
                                    prop="_created"
                                    width="250px"
                                    sortable>
                    </el-table-column>
                    <el-table-column label="Asin"
                                    width="200px"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <a target="_blank" :href="`https://amazon.com/dp/${row.asin}`">{{ row.asin }}</a>
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Image"
                                    width="200px"
                                    >
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <img class="thumbnail-fsg" :src="row.url" alt="">
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total click"
                                    width="100px"
                                    prop="total_click"
                                    >
                    </el-table-column>
                    <el-table-column label="Title"
                                    width="700px"
                                    >
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <strong>{{row.title}}</strong>
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Block click"
                                    min-width="250px"
                                    >
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-button @click="block_click_ads(row)">Block</b-button>
                            </b-media>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <b-card-footer class="py-4 d-flex justify-content-end">
                    <base-pagination v-model="currentPage" :per-page="1" :total="Math.floor(ideas.length/1)"></base-pagination>
                </b-card-footer> -->
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
export default {
    components: {
        vue2Dropzone,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    data: () => ({
        keyword: '',
        repeat: 300,
        upload: false,
        asins: [],
        keywords: {},
        current_day: 0,
        total: 0,
        dropzoneOptions: {
            // url: `https://v2.fecom.io/ads_click`,
            url: `http://0.0.0.0:5001/ads_click`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: '*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
            autoProcessQueue: false,
            addRemoveLinks: true,
        },
    }),
    created() {
    this.sockets.subscribe('click_ads', (current) => {
        var updated = this.asins.filter(e => e.asin == current.asin)
        if (updated.length == 0){
            this.asins.push(current)
        }else{
            updated.forEach((e) => {this.asins[this.asins.indexOf(e)] = current})
        }
        this.$vs.notification({
            title: `Clicked ASIN ${current.asin}`,
            color: "success"
        })
    })
        fsg.get('/ads_click')
            .then(r => {this.asins = r.data.asins, this.total = r.data.total, this.current_day = r.data.current_day, this.keywords = r.data.keywords})
    },
    methods: {
        vdropzone_sending: function(file, xhr, formData){
            formData.append('keyword', this.keyword)
            formData.append('repeat', this.repeat)
        },
        ddos: function(){
            // if (this.$refs.myVueDropzone.getQueuedFiles().length){
            //     this.$refs.myVueDropzone.processQueue()
            // }else{
            //     this.$vs.notification({
            //         title: "Please upload file proxy",
            //         color: "danger"
            //     })
            // }
            fsg.post('/ads_click', {'keyword': this.keyword, 'repeat': this.repeat})
                .then(r => {
                    this.$vs.notification({
                        title: "Ads click processing!",
                        color: "success"
                    })
                })
        },
        block_click_ads: function(row){
            this.asins.splice(this.asins.indexOf(row), 1)
            fsg.put(`/ads_click?asin=${row.asin}`)
                .then(r => {
                    this.$vs.notification({
                        title: r.data.msg,
                        color: 'success'
                    })
                })
        }
    }

}
</script>
<style lang="scss" scoped>
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 250px;
  width: auto;
  min-width: 150px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    height: 270px !important;
    overflow: scroll !important;
}
</style>
