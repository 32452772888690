<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="min-height: 388px; background-image: url(img/theme/amazon.png); background-size: 1700px 388px; background-position:   center center;">
    </base-header>
    <b-container fluid>
      <vs-dialog not-center width="600px" v-model="upload_multi">
        <b-container fluid="">
          <h3 align="center">File csv 1 cột chứa link các store</h3>
          <vue2Dropzone id="dropzonecontent" :options="dropzonecontent"></vue2Dropzone>
        </b-container>
      </vs-dialog>
      <b-row>
        <b-col xl="4">
          <b-card>
            <!-- <base-select required @change="selectedmail" :selected="forward_selected" id="" :options="options" textlabel="Email"></base-select> -->
            <vs-input @keypress.enter="remove_store" id="seller_id" border v-model="seller_id" placeholder="Enter sellerid to remove" />
            <hr>
            <vs-input @keypress.enter="search_kw" id="search_tag" border v-model="block_kw" placeholder="Enter trademark to search" />
            <div style="overflow:scroll; overflow-x; max-height: 200px;">
              <b-row>
                <b-col v-for="tag, idx in blocked" :key="idx" xl="6">
                  <div style="margin: 10px">
                    <span @click="doCopy(tag)" class="tags">{{ tag }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col xl="8">
          <card>
            <!-- <label for="trademark" class="form-control-label">
              Enter brand
            </label>
            <vs-input @keypress.enter="search_trademark" id="trademark" border v-model="trademark" placeholder="trademark" /> -->
            <label for="url" class="form-control-label">
              Enter your Url
            </label>
            <vs-input id="url" border v-model="url" placeholder="Name" />
            <label for="file_name" class="form-control-label">
              File name
            </label>
            <!-- <vs-input id="file_name" border v-model="file_name" placeholder="Block pages" />
            <label for="block" class="form-control-label">
              Block page
            </label>
            <vs-input id="block" border v-model="block" placeholder="Block pages" /> -->
            <vs-button
                shadow
                :active="active == 0"
                @click="pull()"
            >
              Crawl
            </vs-button>
            <vs-button
                shadow
                :active="active == 0"
                @click="upload_multi = !upload_multi"
                danger
            >
              Multi crawl
            </vs-button>

            <label for="file_name" class="form-control-label">
              Detail
            </label>
            <b-row>
              <b-col xl="1">
                <vs-switch v-model="detail" />
              </b-col>
            </b-row>

            <div v-if="readyDownload">
              <hr class="my-4">
              <vs-button
                  @click="download"
              >
                Download
              </vs-button>
              <b-row>
                <b-col v-for="index in items" :key="index" cols="4">
                  <vs-card type="6" style="height: 600px; overflow-y: scroll">
                    <template #title>
                      <hi3>{{ data[index][0] }}</hi3>
                    </template>
                    <template #img>
                      <img :src="data[index][2]" alt="">
                    </template>
                    <template #text>
                      <p>
                        {{ data[index][2] }}
                      </p>
                    </template>
                    <template #interactions>
                      <vs-button
                          @click="rmItem(index)"
                          icon
                          color="danger"
                      >
                        <i class='bx bx-trash' ></i>
                      </vs-button>
                    </template>
                  </vs-card>
                </b-col>
              </b-row>
            </div>
            <vs-pagination v-if="readyDownload" color="success" v-model="page" :length="Math.floor(data.length/perpage) + 1"/>
          </card>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Results</h5>
                <vs-table>
                    <template #thead>
                    <vs-tr>
                        <!-- <vs-th class="vs-td-basic">
                            <b-form-checkbox
                                v-model="check_all"
                                >
                            </b-form-checkbox>
                        </vs-th> -->
                        <vs-th class='vs-td-sx'>
                            Seller id
                        </vs-th>
                        <vs-th class='vs-td-lg'>
                            Store name
                        </vs-th>
                        <vs-th>
                            Date
                        </vs-th>
                        <vs-th>
                            user
                        </vs-th>
                        <vs-th>
                            total
                        </vs-th>
                        <vs-th>
                            processed
                        </vs-th>
                        <vs-th>
                            success
                        </vs-th>
                        <vs-th>
                            error
                        </vs-th>
                        <vs-th>
                          blocked
                        </vs-th>
                        <vs-th class='vs-td-sx'>
                          Download
                        </vs-th>
                    </vs-tr>
                    </template>
                    <template #tbody>
                    <vs-tr
                        :key="i"
                        v-for="(tr, i) in files"
                        :data="tr"
                    >
                        <vs-td class="vs-td-sx">
                            <strong @click="doCopy(tr.seller_id)">{{ tr.seller_id }}</strong>
                        </vs-td>
                        <vs-td class='vs-td-lg'>
                            <strong>{{ tr.brand_name }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.date }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.employee }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.total }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.processed }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.success }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.error }}</strong>
                        </vs-td>
                        <vs-td>
                            <strong>{{ tr.blocked }}</strong>
                        </vs-td>
                        <vs-td class="vs-td-sx">
                          <vs-button
                              warn
                              gradient
                              @click="download_result(tr)"
                              icon
                          >
                              <i class='bx bx-cloud-download' ></i>
                          </vs-button>
                        </vs-td>
                    </vs-tr>
                    </template>
                </vs-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
import XLSX from 'xlsx';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
export default {
  name: "amazon",
  components: {
    vue2Dropzone
  },
  data(){
    return {
      page: 1,
      blocked: [],
      trademark: "",
      upload_multi: false,
      perpage: 24,
      seller_id: '',
      detail: false,
      file_name: 'amazon_unknow',
      items: [0, 1, 2],
      data: [],
      active: 1,
      readyDownload: false,
      files: [],
      url: '',
      csvcontent: '',
      block: 50,
      block_kw: '',
      dropzonecontent: {
          url: `https://v2.fecom.io/amazon?multi=True&detail=${this.detail}`,
          // url: `http://0.0.0.0:5001/amazon?multi=True&detail=${this.detail}`,
          thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: 'text/csv',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 1,
        dictDefaultMessage: 'Upload csv'
      },
    }
  },
  methods: {
    search_trademark: function () {
      const loading = this.$vs.loading()
      fsg.post(`/trademark`, { 'trademark': this.trademark })
        .then(r => {
          loading.close()
          this.$vs.notification({
            title: "Add to queue"
          })
      })
    },
    search_kw: function () {
      this.blocked = this.blocked.filter(e => e == this.block_kw)
    },
    remove_store: function () {
      fsg.delete(`/amazon?seller_id=${this.seller_id}`)
        .then(r => {
          this.$vs.notification({
            title: "removed",
          })
        })
        .catch(error => {
          this.$vs.notification({
            title: "error",
          })
        })

    },
    pull: function (){
      // const loading = this.$vs.loading()
      if (this.detail == true) {
        fsg.post(`/amazon`, {url: this.url, block: this.block, detail: 'true'})
          .then(resp => (
            this.$vs.notification({
              title: resp.data.msg,
              color: 'success'
            })))
            .catch(error => (this.errorNotify('erorr', error.response.data.msg)))
      } else {
        fsg.post(`/amazon?detail=${false}`, {url: this.url, block: this.block,})
          .then(resp => (this.$vs.notification({
              title: resp.data.msg,
              color: 'success'
            })))
            .catch(error => (this.errorNotify('erorr', error.response.data.msg)))

      }
    },
    saveData: function (resp){
      this.data = resp.data.message
    },
    errorNotify: function (title, text, position = 'botton-left', color = 'danger') {
      const noti = this.$vs.notification({
        color,
        position,
        title: title,
        text: text
      })
    },
    download_result: function (store) {
      fsg.get(`/amazon?download=${store.seller_id}`)
        .then(r => this.download_csv(r.data.store, `${store.seller_id} - ${store.brand_name} - ${store.total}`))

    },
    download_csv: function (store_data, fn) {
        const data = XLSX.utils.aoa_to_sheet(store_data)
        const wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, data, 'Sheet')
        XLSX.writeFile(wb, `${fn}.xlsx`)
    },
    doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
    },
    download: function (){
      let content = ''
      this.data.forEach((item) => {
        content += item.join(",")
        content += "\n"
      })
      console.log(content)
      console.log(this.data)
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
      anchor.target = '_blank';
      anchor.download = this.file_name + '.csv';
      anchor.click();
      // const anchor = document.createElement('a')
      // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    },
    rmItem: function (index){
      this.data.splice(index, 1)
    },
  },
  created () {
    fsg.get('/amazon')
      .then(r => this.files = r.data.files)
    fsg.get('/trademark')
      .then(r => this.blocked = r.data.blocked, this.blocked_origin = this.blocked)
    this.dropzonecontent.url = `https://v2.fecom.io/amazon?multi=True&detail=${this.detail}`;
  },
  watch: {
    page() {
      let cached = []
      const start = (this.page - 1) * this.perpage
      const end = this.page * this.perpage
      for (let i = start; i < end; i++) {
        cached.push(i)
      }
      this.items = cached
      console.log(this.items)
    },
    detail() {
      this.dropzonecontent.url = `https://v2.fecom.io/amazon?multi=True&detail=${this.detail}`;
    }
  }
}
</script>

<style lang="scss" scope>
.tags {

    background-color: #ffd600;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    box-shadow: #777;
    border: 1px;
    margin-block: 5px;
    // overflow-y: scroll;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
