<template>
    <div>
        <vs-dialog width="300px" not-center v-model="modal.listingetsy">
            <template #header>
            <h4 class="not-margin">
                <b>Config listing</b>
                <!-- <base-input @keypress.enter="update_tags" v-model="edit.tags_text" /> -->
            </h4>
            </template>
            <b-row>
                <b-col>
                    <vs-select placeholder="Select" v-model="etsy_selected">
                        <vs-option v-for="s in seller_etsy" :label="s.name" :value="s.seller_id" :key="s.seller_id">
                            {{ s.name }}
                        </vs-option>
                    </vs-select>
                </b-col>
                <b-col>
                    <vs-button
                        @click="list_etsy"
                        color="warn"
                        icon
                    >
                        Submit
                    </vs-button>

                </b-col>
            </b-row>
        </vs-dialog>
        <vs-dialog width="1200px" v-model="clone">
            <template #header>
                <h4 class="not-margin">
                    <b>Add new design</b>
                </h4>
            </template>
            <b-container>
                <b-card>
                    <b-row>
                        <b-col xl="12">
                            <vs-input icon-after success v-model="asin" placeholder="Asin">
                                <template #icon>
                                    <i class='bx bxl-amazon'></i>
                                </template>
                            </vs-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <vs-input icon-after success v-model="url" placeholder="Url">
                                <template #icon>
                                    <i class='bx bx-link'></i>
                                </template>
                            </vs-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <vs-input icon-after success v-model="title" placeholder="Title">
                                <template #icon>
                                    <i class='bx bx-rename' ></i>
                                </template>
                            </vs-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <vs-input icon-after success v-model="note" placeholder="Note">
                                <template #icon>
                                    <i class='bx bxs-notepad'></i>
                                </template>
                            </vs-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
                        </b-col>
                        <hr>
                        <b-col>
                            <vue2Dropzone v-if="allow_dropzone" class="upload" id="duplicate" :options="duplicate"></vue2Dropzone>
                        </b-col>
                    </b-row>
                    <vs-button
                            :loading="downloading"
                        @click="add_asin"
                        color="warn"
                        icon
                    >
                        okeeeeeeee
                    </vs-button>
                </b-card>
            </b-container>
        </vs-dialog>
        <vs-dialog v-model="add_toggle">
            <template #header>
                <h4 class="not-margin">
                    <b>Add new design</b>
                </h4>
            </template>
            <b-container>
                <b-card>
                    <b-row>
                        <b-col xl="12">
                            <base-input v-model="new_idea.title" label="Title">
                            </base-input>
                        </b-col>
                        <b-col xl="12">
                            <base-input v-model="new_idea.sku" label="Sku">
                            </base-input>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <base-input label="Description">
                                <textarea v-model="new_idea.Description" class="form-control" rows="12"></textarea>
                            </base-input>
                        </b-col>
                        <b-col xl="12">
                            <base-input label="Results">
                                <textarea v-model="new_idea.parent_url" class="form-control" rows="5"></textarea>
                            </base-input>
                        </b-col>
                        <hr>
                        <b-col v-if="new_idea.seed_images.length > 0">
                            <div class="image-card" v-for="(img, index) in new_idea.seed_images" :key="index">
                                <b-row>
                                    <b-col xl="3">
                                        <div class="image-card-detail-item">
                                            <a :href="img" target="_blank">
                                                <img class="img-thumbnail" :src="img" alt="">
                                            </a>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <h4 style="text-align: center;">
                                            {{ index + 1 + ".jpg"}}
                                        </h4>
                                    </b-col>
                                    <b-col v-if="['mod', 'admin'].includes(role)">
                                        <vs-button
                                            :loading="downloading"
                                            @click="delete_seed(index)"
                                            color="danger"
                                            icon
                                        >
                                            delete
                                        </vs-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col xl="12" v-if="['mod', 'admin'].includes(role)">
                            <div>
                                <vs-button
                                    :loading="downloading"
                                    @click="allow_dropzone = !allow_dropzone"
                                    color="danger"
                                    icon
                                >
                                    Add image
                                </vs-button>
                            </div>
                        </b-col>
                        <hr>
                        <b-col xl="12">
                            <vue2Dropzone @vdropzone-error="vdropzone_error" @vdropzone-success="vdropzone_success" v-if="allow_dropzone" class="upload" id="duplicate" :options="duplicate"></vue2Dropzone>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2">
                            <vs-button
                                v-if="['mod', 'admin', 'designer'].includes(role)"
                                :loading="downloading"
                                @click="add_idea"
                                color="warn"
                                icon
                            >
                                submit
                            </vs-button>
                        </b-col>
                        <b-col cols="2">
                            <vs-button
                                v-if="['mod', 'admin', 'designer'].includes(role)"
                                :loading="downloading"
                                @click="done_idea"
                                color="success"
                                icon
                            >
                                done
                            </vs-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-container>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="modal.tags">
            <template #header>
            <h4 class="not-margin">
                <b>Add tag</b>
                <base-input @keypress.enter="update_tags" v-model="edit.tags_text" />
            </h4>
            </template>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="editActive">
            <template #header>
            <h4 class="not-margin">
                <b>Edit note</b>
            </h4>
            </template>
            <base-input @keypress.enter="update_note" v-model="edit_note" />
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="delete_confirm">
            <template #header>
            <h4 class="not-margin">
                <b>Do you want delete it {{ edit.asin }}?</b>
            </h4>
            </template>

            <template #footer>
            <div>
                <vs-button @click="delete_asin" transparent>
                    Ok
                </vs-button>
                <vs-button @click="delete_confirm = false" dark transparent>
                    Cancel
                </vs-button>
            </div>
            </template>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="modal.confirm_design">
          <b-container fluid="">
            <h3 align="center">Select products</h3>
            <vs-select
                placeholder="Products"
                label="Products"
                multiple
                id="products"
                v-model="selected_products"
            >
                <vs-option value="poster" label="Poster">
                    Poster
                </vs-option>
                <vs-option value="mug" label="Mug">
                    Mug
                </vs-option>
                <vs-option value="phone-case" label="Phone case">
                    Phone case
                </vs-option>
                <vs-option value="pillow" label="Pillow">
                    Pillow
                </vs-option>
                <vs-option value="tote" label="Tote">
                    Tote
                </vs-option>
                <vs-option value="blanket" label="Blanket">
                    Blanket
                </vs-option>
            </vs-select>
            <hr>
            <vs-button @click="push_to_recontent" transparent>
                Confirm
            </vs-button>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Mỗi lượt up từng file cho từng asin</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <b-container fluid>
            <b-card>
                <b-row>
                    <b-col xl="2">
                        <vs-button
                             :loading="downloading"
                            @click="reset_add_idea"
                            color="success"

                            icon
                        >
                           Add
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                             :loading="downloading"
                            @click="clone = !clone"
                            color="success"

                            icon
                        >
                           Another
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                            warn
                            gradient
                            @click="upload = true"
                            icon
                        >
                            <!-- <i class='bx bx-upload' ></i> -->
                            Uploads
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="modal.confirm_design = !modal.confirm_design, selected_products = []"
                            color="warn"
                            icon
                        >
                           Push to recontent
                        </vs-button>
                    </b-col>
                    <b-col xl="2">
                        <vs-button
                            @click="make_sku"
                            color="danger"
                            icon
                        >
                           Random sku
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="etsy_platform"
                            color="success"
                            icon
                        >
                           Listing
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-select placeholder="Sellers" v-model="selected_seller">
                            <vs-option v-for="(e, idx) in sellers" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                                {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col>
                    <b-col>
                        <vs-select placeholder="Employee" v-model="selected_employee">
                            <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                                {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_asin" v-model="search" placeholder="Search asin" />
                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_tags" v-model="tag_search" placeholder="Search by tags" />
                    </b-col>
                    <hr>
                    <b-col xl="12">
                        <vs-pagination color="success" v-model="page" :length="Math.floor(all.length/perpage) + 1"/>
                        <vs-table>
                            <template #thead>
                            <vs-tr>
                                <vs-th>
                                    <b-form-checkbox
                                        v-model="check_all"
                                        >
                                    </b-form-checkbox>
                                </vs-th>
                                <vs-th class='vs-td-ssx'>
                                    Upload
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Amazon
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Image
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Type
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Date assigned
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Seller
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Designer
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Priority
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Title
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Tags
                                </vs-th>
                                <vs-th class='vs-td-min'>
                                    Note
                                </vs-th>
                            </vs-tr>
                            </template>
                            <template #tbody>
                            <vs-tr
                                :key="i"
                                v-for="(tr, i) in asins"
                                :data="tr"
                                class="priority"

                            >
                                <vs-td class="vs-td-basic" @click="add_toggle = !add_toggle, new_idea = tr">
                                    <b-form-checkbox @change="choise_product(tr)"
                                        v-model="tr.selected"
                                        >
                                    </b-form-checkbox>
                                </vs-td>
                                <vs-td class='vs-td-ssx'>
                                    <vs-button
                                        danger
                                        gradient
                                        @click="edit = tr, delete_confirm = true"
                                        icon
                                    >
                                        <i class='bx bx-trash-alt' ></i>
                                    </vs-button>
                                </vs-td>
                                <vs-td class="vs-td-sx" @click="add_toggle = !add_toggle, new_idea = tr">
                                    <vs-td class="fix-border" style="width: 2% !important">
                                        <a :href="tr.secondary_url" target="_blank">
                                            <img style="width: 100px; height: auto" :src="tr.secondary_url" alt="">
                                        </a>
                                        <div @click="doCopy(tr.sku)" v-if="tr.is_amazon === true">
                                            <strong >{{ tr.asin }}</strong>
                                        </div>
                                        <div v-else-if="tr.origin_url"  @click="add_toggle = !add_toggle, new_idea = tr">
                                            <!-- <a :href="tr.origin_url" target="_blank"> -->
                                                <strong >{{ tr.asin }}</strong>
                                            <!-- </a> -->
                                        </div>
                                        <div v-else>
                                            <!-- <a :href="tr.secondary_url" target="_blank"> -->
                                                <strong @click="doCopy(tr.sku)">{{ tr.asin }}</strong>
                                            <!-- </a> -->
                                        </div>

                                    </vs-td>
                                    <vs-td class="fix-border">
                                            <!-- <div style="display: inline">
                                            </div> -->
                                    </vs-td>
                                </vs-td>
                                <vs-td class='vs-td-ssx'>
                                    <vs-td class="fix-border">
                                        <div>
                                            <router-link  target="_blank" :to="{path: 'designs', query: {sku: tr.asin}}">
                                                <img style="width: 100px; height: auto" :src="tr.url" alt="">
                                            </router-link>
                                        </div>
                                    </vs-td>
                                    <vs-td class="fix-border">
                                        <div>
                                            <strong class="a-yellow">{{ tr.date_upload }}</strong>
                                        </div>
                                    </vs-td>
                                </vs-td>
                                <vs-td class="vs-td-sm"  @click="add_toggle = !add_toggle, new_idea = tr">
                                    <strong>{{ tr.type }}</strong>
                                </vs-td>
                                <vs-td class="vs-td-sm" @click="add_toggle = !add_toggle, new_idea = tr">
                                    <strong class="a-green">{{ tr.date_assign }} </strong>
                                </vs-td>
                                <vs-td class="vs-td-sm">
                                    <a :href="'https://t.me/' + tr.seller_tele_user" target="_blank">{{ tr.seller }}</a>
                                </vs-td>
                                <vs-td class="vs-td-sm">
                                    <a :href="'https://t.me/' + tr.designer_tele_user" target="_blank">{{ tr.designer }}</a>
                                </vs-td>
                                <vs-td class="vs-td-sm">
                                    <base-select class="form-control-label" required :isSelected="tr.priority" @change="update_priority" :v-model="tr.priority"  :options="priorities" :data="tr"></base-select>
                                </vs-td>
                                <vs-td :style="{ color: !tr.parent_url ? 'black' : 'green' }" @click="add_toggle = !add_toggle">
                                    {{ tr.title }}
                                </vs-td>
                                <vs-td @click="edit=tr, modal.tags = !modal.tags" class="vs-td-sx">
                                    <div v-for="tag in tr.tags" :key="tag" style="margin: 10px 10px 10px 10px;">
                                        <span @click="doCopy(tag)" class="tags">{{ tag }}</span>
                                    </div>
                                </vs-td>
                                <vs-td edit @click="edit = tr, editActive = true, edit_note = edit.note, editProp = 'note'" class='vs-td-min'>
                                    <strong style="min-width: 100px">{{ tr.note }}</strong>
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                    </b-col>
                <hr>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
import Products from '../../../components/Products.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import BaseSelect from "@/components/BaseSelect";
export default {
  name: "redesign",
  components: {
    Products,
    vue2Dropzone,
    BaseSelect
  },
    data: () => ({
        modal: {
            tags: false,
            listingetsy: false,
            confirm_design: false
        },
        priorities: [
            {'text': 'High', 'value': '2'},
            {'text': 'Low', 'value': '3'},
            {'text': 'Order', 'value': '1'},
        ],
        selected_products: [],
        role: '',
        new_idea: {
            sku: '',
            title: '',
            Description: '',
            seed_images: [],
            parent_url: ''
        },
        items: [],
        seller_etsy: [],
        etsy_selected: '',
        all: [],
        page: 0,
        perpage: 48,
        products: [],
        tag_search: '',
        clone: false,
        edit_asin: '',
        edit_note: '',
        search: '',
        url: '',
        title: '',
        note: '',
        product: '',
        add_toggle: false,
        editing: false,
        employee_id: localStorage.getItem('id'),
        role: localStorage.getItem('role'),
        selected_employee: '',
        selected_seller: '',
        check_all: false,
        scopes: ['mod', 'admin'],
        asin: '',
        employees: [],
        editActive: false,
        editProp: '',
        asins: [],
        delete_confirm: false,
        edit: Object,
        downloading: false,
        allow_dropzone: false,
        upload: false,
        dropzoneOptions: {
            url: `https://v2.fecom.io/designs?redesign=oke`,
            // url: `http://0.0.0.0:5001/designs?redesign=oke`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: true,
            dictUploadCanceled: true,
            dictInvalidFileType: true,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        duplicate: {
        url: 'https://v2.fecom.io/seed_images',
        // url: 'http://0.0.0.0:5001/seed_images',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: '*',
        headers: {
            "fsg-token": localStorage.getItem("fsg-token"),
            // "type": "sku-asin",
            "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        uploadMultiple: true,
        dictInvalidFileType: false,
        maxFiles: 20,
        dictDefaultMessage: 'Images',
        parallelUploads: 10000,
        timeout: 1800000,
        },
    }),
        dropzonecontent: {
            url: `https://v2.fecom.io/redesign/upload_content`,
            // url: `http://0.0.0.0:5001/redesign/upload_content`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: true,
            dictUploadCanceled: true,
            dictInvalidFileType: true,
            maxFiles: 1,
        },
    methods: {
        list_etsy: function () {
            var ideas = []
            this.asins.filter(e => {
                if(e.selected) {
                    ideas.push(e.asin)
                }
            })
            fsg.post(`/sellers/etsy?seller_id=${this.etsy_selected}`, {'skus': ideas})
            .then(r => this.$vs.notification({
                        title: r.data.msg,
                        color: "success",
                        })
            )
        },
        etsy_platform: function () {
            var ideas = []
            this.asins.filter(e => {
                if(e.selected) {
                    ideas.push(e.asin)
                }
            })
            fsg.put(`/etsy/listing`, {'skus': ideas})
            .then(r => this.$vs.notification({
                        title: r.data.msg,
                        color: "success",
                        })
            )

        },
        update_priority: function(selected, data){
            if (this.scopes.includes(localStorage.getItem("role"))){
                data.priority = selected
                fsg.patch(`/redesign`, data)
                    .then(r => {
                        this.$vs.notification({
                            title: 'Priority updated',
                            color: 'success'
                        })
                    })
                    .catch(err => {
                        this.$vs.notification({
                            title: 'Note update failed',
                            color: 'success'
                        })
                    })
            }
        },
        vdropzone_error: function(file){
            this.$vs.notification({
                title: `Upload file ${file.name} error`
            })
        },
        choise_product: function (item) {
            if (item.selected) {
                this.items.push(item.asin)
            } else {
                var index = this.items.indexOf(item.asin)
                if (index !== -1) {
                    this.items.splice(index, 1)
                }
            }
        },
        add_asin: function(){
            this.downloading = true
            fsg.post(`/redesign?asin=${this.asin}&type=${this.product}`, {'url': this.url, 'title': this.title, 'note': this.note, 'product': this.product})
                .then(r => {
                    this.asins.unshift.apply(this.asins, r.data.msg),
                    this.downloading = false
                    this.upload = false
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        vdropzone_success: function(file, resp){
            resp.seed_images.forEach(image => {
                if (!this.new_idea.seed_images.includes(image)) {
                    this.new_idea.seed_images.push(image);
                }
            });
        },
        reset_add_idea: function () {
            this.add_toggle = !this.add_toggle
            this.new_idea = {
                sku: '',
                title: '',
                Description: '',
                seed_images: []
            }
        },
        update_tags: function () {
            this.modal.tags = false
            fsg.post("/product_tags", {'tags': this.edit.tags_text.split(","), 'sku': this.edit.asin})
                .then(r => {
                    this.$vs.notification({
                        title: "Updated",
                        color: "warning"
                    })
                    this.edit.tags = this.edit.tags_text.split(",")
                })
        },
        delete_seed: function (index) {
            this.downloading = true
            this.new_idea.seed_images.splice(index, 1)
            fsg.post(`/ideas`, this.new_idea)
                .then(r => {
                    this.downloading = false
                    this.new_idea.sku = r.data.sku
                    this.$vs.notification({
                        title: `Updated idea ${this.new_idea.sku}`,
                        color: "success"
                    })
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        make_sku: function () {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 8; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
            }
            this.doCopy(result)
        },
        search_tags: function () {
            this.$vs.notification({
                'title': 'Searching tag',
                'color': 'warning'
            })
            fsg.get(`/redesign?exported=oke&tags=${this.tag_search}`)
                .then(r => { this.asins = r.data.msg; if (this.role == 'designer'){this.asins = this.asins.filter(e => e.employee_id == this.employee_id)}})
        },
        search_asin: function(){
            fsg.get(`redesign?asin=${this.search}`)
                .then(r => {this.asins = r.data.msg})
        },
        push_to_recontent: function(){
            // let selected = this.asins.filter((e) => e.selected == true)
            // var data = []
            // selected.forEach(e => data.push(e.asin))
            fsg.put(`/redesign?confirm_design=oke`, {'data': this.items, 'products': this.selected_products})
                .then(r => {
                    this.$vs.notification({
                        'title': 'Push to redesign',
                        'color': 'success'
                    })
                })
        },
        update_product: function(){
            fsg.put('/update_product', this.edit)
                .then(r => {
                    console.log("hihi")
                })
        },
        done_idea: function(){
            this.downloading = true
            fsg.post(`/ideas?done=True`, this.new_idea)
                .then(r => {
                    this.downloading = false
                    this.new_idea.sku = r.data.sku
                    this.$vs.notification({
                        title: `Added idea ${this.new_idea.sku}`,
                        color: "success"
                    })
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        add_idea: function(){
            this.downloading = true
            fsg.post(`/ideas`, this.new_idea)
                .then(r => {
                    this.downloading = false
                    this.new_idea.sku = r.data.sku
                    this.$vs.notification({
                        title: `Added idea ${this.new_idea.sku}`,
                        color: "success"
                    })
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        update_dropzone: function (val){
            this.product = val
            this.dropzoneOptions.url = `https://v2.fecom.io/designs?type=${val}&redesign=oke`
            // this.duplicate.url = `https://v2.fecom.io/redesign?type=${val}&redesign=oke`
            this.allow_dropzone = true
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        delete_asin: function(){
            fsg.delete(`/redesign?asin=${this.edit.asin}`)
                .then(r => {
                    this.delete_confirm = !this.delete_confirm
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })
        },
        update_note: function(){
            this.edit.note = this.edit_note
            fsg.put(`/redesign?asin=${this.edit.asin}&note=${this.edit.note}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Note updated',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Note update failed',
                        color: 'success'
                    })
                })
        }
    },
    beforeDestroy() {
        this.sockets.unsubscribe('assign_designer')
    },
    created() {
        this.role = localStorage.getItem('role')
        fsg.get("/products?scope=all").then(resp => {this.products = resp.data.message})
        fsg.get('/redesign')
            .then(r => { this.all = r.data.msg; if (this.role == 'designer') {this.all = this.all.filter(e => e.design_user_id == this.employee_id || e.priority == '1') }; this.page = 1;  if(this.all.length < this.perpage){this.perpage = this.all.length}})
        fsg.get('/employees?team=designer')
            .then(r => {this.employees = r.data.message})
        fsg.get('/employees?team=seller')
            .then(r => {this.sellers = r.data.message})
    },
    watch: {
        page() {
            let cached = []
            const start = (this.page - 1) * this.perpage
            const end = this.page * this.perpage
            for (let i = start; i < end; i++) {
                cached.push(this.all[i])
            }
            this.asins = cached
        },
        check_all: function(){
            this.asins.forEach(e => e.selected = !e.selected)
        },
        selected_seller: function(){
            this.asins = this.all.filter(e => e.employee == this.selected_seller)
        },
        selected_employee: function(){
            var selected = this.asins.filter(e => e.selected)
            if (this.selected_employee == ''){
                return
            }
            this.items = []
            selected.forEach(e => this.items.push(e.asin))
            if (selected.length > 0){
                fsg.put(`/redesign?action=update_employee`, {'asins': this.items, 'employee': this.selected_employee})
                    .then(r => {
                        this.$vs.notification({
                            title: 'Updated employee',
                            color: 'success'
                        })
                    })
            }else {
                this.asins = this.all.filter(e => e.designer == this.selected_employee)
            }
        },
        add_toggle: function () {
            if (this.add_toggle == false) {
                this.allow_dropzone = false
            }
        }
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.tags {

    background-color: #ffd600;
    padding: 2px 5px 2px 5px;
    border-radius: 2px;
    box-shadow: #777;
    border: 1px;
    margin-block: 5px;
}
.vs-td-sx {
  width: 2%;
}
.vs-td-min{
    min-width: 200px !important;
}
.vs-td-ssx {
  width: 1%;
}
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 2%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 1%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.fix-border {
    border: none !important
}
.form-control {
    // width: auto;
    margin: auto;
}
.image-card {
    width: 100%;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
    margin-top: 10px;
    background-color: rgb(238, 238, 238);
    border: 0px;
    border-radius: 5px;
}
.image-card-detail-item {
    margin-left: 10px;
}
.vs-dialog {
    min-width: 800px;
    min-width: 1500px;
    min-height: 800px;
    overflow: hidden;
}
.container {
    margin-right: 40px;
    margin-left: 20px;
    max-width: none;
    max-height: none;
}
.priority {
    background-color: hsl(0, 72%, 80%) !important;
}

</style>
