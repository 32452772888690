<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="min-height: 388px; background-image: url(img/theme/etsy.png); background-size: 1700px 388px; background-position:   center center;">
    </base-header>
    <b-container fluid>
      <card>
        <vs-dialog width="550px" not-center v-model="dialog.add_config">
            <template #header>
            <h4 class="not-margin">
                <b>Add config</b>
            </h4>
            </template>
            <vs-select placeholder="Select" v-model="variations.type">
              <vs-option label="Sizes" value="sizes">
                Sizes
              </vs-option>
              <vs-option label="Colors" value="colors">
                Colors
              </vs-option>
            </vs-select>
            <!-- <label for="size-name" class="form-control-label">
              Variation name, example: Tshirt-DatPro
            </label>
            <vs-input id="size-name" place v-model="variations.name" /> -->
            <label for="sizes" class="form-control-label">
              Variation value, example: XS, S, M, L, XL
            </label>
            <vs-input id="sizes" place v-model="variations.value" />
            <vs-button
              :active="active == 0"
              @click="add_variation"
              danger
            >
              Submit
            </vs-button>
        </vs-dialog>
        <b-row>
          <b-col lg="3">
            <base-select @change="selectedSearch" required id="search_type" :options="search_type" textlabel="Select search"></base-select>
          </b-col>
          <b-col lg="1">
          </b-col>
        </b-row>
        <label for="keyword" class="form-control-label">
          Enter your keyword
        </label>
        <vs-input id="keyword" border v-model="keyword"/>

        <label for="extendTitle" class="form-control-label">
          Extend Title
        </label>
        <vs-input id="extendTitle" border v-model="extendTitle"/>

        <label for="extendTag" class="form-control-label">
          Extend Tag
        </label>
        <vs-input id="extendTag" border v-model="extendTag"/>

        <label for="filename" class="form-control-label">
          File name
        </label>
        <vs-input id="filename" border v-model="filename" />

        <label for="block" class="form-control-label">
          Block page
        </label>
        <vs-input id="block" border v-model="block" />
        <b-row>
          <b-col cols="1">
            <vs-button
              :active="active == 0"
              @click="pull()"
              success
            >
              Crawl
            </vs-button>
          </b-col>
          <b-col cols="1">
            <vs-button
              :active="active == 0"
              @click="dialog.add_config =!dialog.add_config"
              danger
            >
              Variations
            </vs-button>

          </b-col>
        </b-row>
        <div v-if="readyDownload">
          <hr class="my-4">
          <vs-button
            @click="download"
          >
            Download
          </vs-button>
          <b-row>
            <b-col v-for="index in items" :key="index" cols="4">
              <vs-card type="6" style="height: 600px; overflow-y: scroll">
                <template #title>
                  <h3>{{ data[index][0] }}</h3>
                </template>
                <template #img>
                  <img :src="data[index][2]" alt="">
                </template>
                <template #text>
                  <p>
                    {{ data[index][1] }}
                  </p>
                </template>
                <template #interactions>
                  <vs-button
                    @click="rmItem(index)"
                    icon
                    color="danger"
                  >
                    <i class='bx bx-trash' ></i>
                  </vs-button>
                </template>
              </vs-card>
            </b-col>
          </b-row>
        </div>
        <vs-pagination v-if="readyDownload" color="success" v-model="page" :length="Math.floor(data.length/perpage) + 1"/>
      </card>
    </b-container>
  </div>
</template>

<script>
import fsg from "@/api/base";
import BaseSelect from "@/components/BaseSelect";
export default {
  name: "etsy",
  components: {
    BaseSelect
  },
  data(){
    return {
      dialog: {
        add_config: false
      },
      variations: {
          value: '',
          type: ''
      },
      searchType: 'keyword',
      page: 1,
      perpage: 24,
      items: [0, 1, 2],
      data: [],
      active: 1,
      readyDownload: false,
      keyword: '',
      csvcontent: '',
      block: 1,
      filename: 'unknow_filename',
      extendTitle: '',
      extendTag: '',
      search_type: [
        {
          label: "Keyword",
          options: [
            {value: 'keyword', text: "Keyword"},
          ]
        },
        {
          label: "Shop",
          options: [
            {value: 'shop', text: "Shop"},
          ]
        },
      ],
    }
  },
  methods: {
    add_variation: function(){
      fsg.post('/variations', this.variations)
      .then(r => {
        this.errorNotify(r.data.msg, '')
      })
    },
    selectedSearch: function (val) {
      this.searchType = val
      this.loadTemplate(val)
    },
    pull: function (){
      const loading = this.$vs.loading()
      fsg.post(`/etsy`, {keyword: this.keyword, block: this.block, extendTitle: this.extendTitle, extendTag: this.extendTag, search_type: this.searchType})
        .then(resp => (this.saveData(resp), this.readyDownload = true, loading.close()))
        .catch(error => (this.errorNotify('erorr', error.response.data.message), loading.close()))
    },
    saveData: function (resp){
      this.data = resp.data.message
    },
    errorNotify: function (title, text, position = 'botton-left', color = 'danger') {
        const noti = this.$vs.notification({
          color,
          position,
          title: title,
          text: text
        })
      },
    download: function (){
      let content = ''
      this.data.forEach((item) => {
        if (!item){
          return
        }
        content += item.join(",")
        content += "\n"
      })
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
      anchor.target = '_blank';
      anchor.download = `${this.filename}.csv`;
      anchor.click();
    },
    rmItem: function (index){
      this.data.splice(index, 1)
    },
  },
  watch: {
    page() {
      let cached = []
      const start = (this.page - 1) * this.perpage
      const end = this.page * this.perpage
      for (let i = start; i < end; i++) {
        cached.push(i)
      }
      this.items = cached
      console.log(this.items)
    }
  }
}
</script>

<style scoped>
</style>
