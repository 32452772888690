<template>
    <div>
      <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 100px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
      </base-header>
      <vs-dialog v-model="asin_popup">
        <template #header>
          <h4 class="not-margin">
            Asin detail
          </h4>
        </template>
        <div v-for="(asin, idx) in asins" :key="idx">
          {{ asin }}
        </div>
      </vs-dialog>
      <b-container fluid class="mt--7">
        <b-row>
          <b-col xl="12">
            <b-card id="con-uploads">
              <b-col xl="12">
                <vs-input @keypress.enter="get_asin" v-model="asin" placeholder="Asin">
                  <template #icon>
                    <i class='bx bx-search-alt'></i>
                  </template>
                </vs-input>
              </b-col>
              <b-col xl="12">
                <vue2Dropzone class="upload" ref="myVueDropzone" id="duplicate" :options="duplicate"></vue2Dropzone>
              </b-col>
              <b-col xl="12">
                <vue2Dropzone class="upload" ref="myVueDropzone" id="remove" :options="remove"></vue2Dropzone>
              </b-col>
              <b-col xl="12">
                <vue2Dropzone class="upload" ref="myVueDropzone" id="remove_option" :options="asin_sku"></vue2Dropzone>
              </b-col>
              <b-col xl="12">
                <vue2Dropzone class="upload" ref="myVueDropzone" id="upload_mockup" :options="upload_mockup" @vdropzone-success-multiple="vdropzone_success"></vue2Dropzone>
              </b-col>
              <b-col xl="12">
                <vue2Dropzone class="upload" ref="myVueDropzone" id="get_url" :options="get_url" @vdropzone-success-multiple="get_url_success"></vue2Dropzone>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import fsg from "@/api/base";
  export default {
    name: "uploads",
    components: {
      vue2Dropzone
    },
    data:() => ({
      upload_type: "",
      asin: "",
      asins: [],
      sellers: [],
      results: [],
      active: false,
      asin_popup: false,
      duplicate: {
        url: 'https://v2.fecom.io/duplicate_asin',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: '*',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "type": "sku-asin",
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 10,
        dictDefaultMessage: 'Remove duplicate'
      },
      remove: {
        url: 'https://v2.fecom.io/remove_asin',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: 'text/csv',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "type": "sku-asin",
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 1,
        dictDefaultMessage: 'Remove asin'
      },
      asin_sku: {
        url: 'https://v2.fecom.io/remove_option',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: 'text/csv',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "type": "sku-asin",
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 1,
        dictDefaultMessage: 'upload asin sku để remove option(File name đặt tên là seller_id.csv của acc đó)'
      },
      upload_mockup: {
        url: 'https://v2.fecom.io/image_to_url',
        // url: 'http://192.168.31.15:5001/image_to_url',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: 'image/*',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "type": "sku-asin",
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 150,
        uploadMultiple: true,
        dictInvalidFileType: false,
        dictDefaultMessage: 'Mockups image to url',
        parallelUploads: 150,
      },
      get_url: {
        url: 'https://v2.fecom.io/get_url',
        // url: 'http://192.168.31.15:5001/image_to_url',
        thumbnailWidth: 150,
        maxFilesize: 30,
        acceptedFiles: 'image/*',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "type": "sku-asin",
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 150,
        uploadMultiple: true,
        dictInvalidFileType: false,
        dictDefaultMessage: 'Upload ảnh lấy link bình thường, không phải up mockup',
        parallelUploads: 150,
      },

    }),
    props: {
      show: Boolean
    },
    watch: {
      show: function (){
        this.active = true
      }
    },
    created() {

    },
    methods: {
      get_asin: function (){
        const loading = this.$vs.loading({
          target: this.$refs.orders,
          color: '#d5397b'})
        fsg.get(`/asins/${this.asin}`)
          .then(r => {this.asins = r.data.msg, this.asin_popup = true, loading.close()})
          .error(r => loading.close())
      },
      vdropzone_error: function(file){
          this.$vs.notification({
              title: `Upload file ${file.name} error`
          })
      },
      export_csv: function(){
            let content = ''
            this.results.forEach((e) => {
                content += e.join(",")
                content += "\n"
            })
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
            anchor.target = '_blank';
            anchor.download = 'result.csv';
            anchor.click();
        },
      vdropzone_success: function(file, resp){
          this.results = resp.msg
          this.export_csv()
      },
      doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
          title: `Copied ${val}`,
          color: "success",
        })
      },
      get_url_success: function(file, resp){
        if (resp.data.length == 1){
          this.$vs.notification({
              title: `Got url ${resp.url} success`
          })
          this.doCopy(resp.url)
        }else{
          this.results = resp.data
          this.export_csv()
        }
      },
    }
  }
</script>
<style scoped>
.upload {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 200px;
  padding: 10px;
  border: 1px;
  margin: 30px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
  min-width: 100% !important;
}
</style>
