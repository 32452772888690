<template>
    <div>
        <vs-dialog width="550px" not-center v-model="editActive">
            <template #header>
            <h4 class="not-margin">
                <b>Edit note</b>
            </h4>
            </template>
            <vs-input @keypress.enter="update_note" v-if="editProp == 'note'" v-model="edit_note" />
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="viewContent">
            <div>
                {{ edit.title }}
            </div>
            <br>
            <div>
                {{ edit.Description }}
            </div>
            <br>
            <div>
                {{ edit.keyword }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point1 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point2 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point3 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point4 }}
            </div>
            <br>
            <div>
                {{ edit.bullet_point5 }}
            </div>
        </vs-dialog>
        <vs-dialog width="600px" v-model="editing">
            <h3>Edit</h3>
            <hr>
            <vs-input v-model="edit.title" label-placeholder="Title">
                {{ edit.title }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.Description" label-placeholder="Description">
                {{ edit.Description }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.keyword" label-placeholder="Keyword">
                {{ edit.keyword }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point1" label-placeholder="bullet_point1">
                {{ edit.bullet_point1 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point2" label-placeholder="bullet_point2">
                {{ edit.bullet_point2 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point3" label-placeholder="bullet_point3">
                {{ edit.bullet_point3 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point4" label-placeholder="bullet_point4">
                {{ edit.bullet_point4 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.bullet_point5" label-placeholder="bullet_point5">
                {{ edit.bullet_point5 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_1" label-placeholder="kw_ppc_1">
                {{ edit.kw_ppc_1 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_2" label-placeholder="kw_ppc_2">
                {{ edit.kw_ppc_2 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_3" label-placeholder="kw_ppc_3">
                {{ edit.kw_ppc_3 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_4" label-placeholder="kw_ppc_4">
                {{ edit.kw_ppc_4 }}
            </vs-input>
            <hr>
            <vs-input v-model="edit.kw_ppc_5" label-placeholder="kw_ppc_5">
                {{ edit.kw_ppc_5 }}
            </vs-input>
            <hr>
            <vs-button
                success
                gradient
                @click="update_product"
            >
                confirm
            </vs-button>
        </vs-dialog>
        <vs-dialog width="550px" not-center v-model="delete_confirm">
            <template #header>
            <h4 class="not-margin">
                <b>Do you want delete it {{ edit.asin }}?</b>
            </h4>
            </template>

            <template #footer>
            <div>
                <vs-button @click="delete_asin" transparent>
                    Ok
                </vs-button>
                <vs-button @click="delete_confirm = false" dark transparent>
                    Cancel
                </vs-button>
            </div>
            </template>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload_ppc">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <div>
              <p style="color: darkred">File csv 1 row sku</p>
              <hr size="3">
              <vue2Dropzone @vdropzone-success="vdropzone_success" id="dropzoneppc" :options="dropzonePPC"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload">
          <b-container fluid="">
            <h3 align="center">Upload design</h3>
            <products label="Select product" @change="update_dropzone" class="flex-item"></products>
            <div v-if="allow_dropzone">
              <p style="color: darkred">Mỗi lượt up từng file cho từng asin</p>
              <hr size="3">
              <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
            </div>
          </b-container>
        </vs-dialog>
        <vs-dialog not-center width="600px" v-model="upload_content">
          <b-container fluid="">
            <h3 align="center">Upload content</h3>
            <vue2Dropzone id="dropzonecontent" :options="dropzonecontent"></vue2Dropzone>
          </b-container>
        </vs-dialog>
        <b-container fluid>
            <b-card>
                <b-row>
                    <b-col xl="2">
                        <vs-button
                            @click="upload_metadata"
                            color="danger"
                            icon
                        >
                           Upload content
                        </vs-button>
                    </b-col>
                    <b-col>
                        <products label="Select product" @change="select_product" class="flex-item"></products>
                    </b-col>
                    <b-col>
                        <vs-select placeholder="Employee" v-model="selected_employee">
                            <vs-option value="all">
                                all
                            </vs-option>
                            <vs-option v-for="(e, idx) in employees" :key="idx" :label="e.name" :value="e.name ? e.name : ''">
                            {{ e.name }}
                            </vs-option>
                        </vs-select>

                    </b-col>
                    <b-col>
                        <vs-button
                            @click="export_csv"
                            color="danger"
                            icon
                            :loading="exporting"
                        >
                           Export
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="upload_ppc = !upload_ppc"
                            color="warn"
                            icon
                            :loading="exporting"
                        >
                           Download ppc
                        </vs-button>
                    </b-col>
                    <b-col>
                        <vs-input @keypress.enter="search_asin" v-model="search" placeholder="Search sku" />
                    </b-col>
                    <b-col>
                        <vs-button
                            @click="download_mockup"
                            color="danger"
                            icon
                            :loading="exporting"
                            v-if="exported"
                        >
                           Download mockup
                        </vs-button>
                    </b-col>
                    <hr>
                    <b-col xl="12">
                        <vs-table>
                            <template #thead>
                            <vs-tr>
                                <vs-th class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="check_all"
                                        >
                                    </b-form-checkbox>
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Product
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Image
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                    Note
                                </vs-th>
                                <vs-th class="vs-td-lg">
                                    Content
                                </vs-th>
                                <vs-th class='vs-td-sx'>
                                Employee
                                </vs-th>
                            </vs-tr>
                            </template>
                            <template #tbody>
                            <vs-tr
                                :key="i"
                                v-for="(tr, i) in paginatedAsins"
                                :data="tr"
                            >
                                <vs-td class="vs-td-basic">
                                    <b-form-checkbox
                                        v-model="tr.selected"
                                        >
                                    </b-form-checkbox>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <strong>{{ tr.type }}</strong>
                                </vs-td>
                                <vs-td class='vs-td-sx'>
                                    <div>
                                        <router-link  target="_blank" :to="{path: 'designs', query: {sku: tr.asin}}">
                                            <img style="width: 100px; height: auto" :src="tr.url" alt="">
                                        </router-link>
                                        <a :href="tr.original_url" target="_blank">
                                            <strong @click="doCopy(tr.asin)">{{ tr.asin }}</strong>
                                        </a>
                                    </div>
                                </vs-td>
                                <vs-td edit @click="edit = tr, editActive = true, edit_note = edit.note, editProp = 'note'" class='vs-td-sx'>
                                    <strong @click="doCopy(tr.note)">{{ tr.note }}</strong>
                                </vs-td>
                                <vs-td class="vs-td-lg">
                                    <b-row>
                                        <b-col xl="11" :class="{'a-green': tr.Description}">
                                            <p @click="edit = tr, viewContent = !viewContent">
                                                {{ tr.title }}
                                            </p>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                warn
                                                gradient
                                                @click="editing = !editing, edit = tr"
                                                icon
                                            >
                                                <i class='bx bxs-edit-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_redesign(tr)"
                                                icon
                                            >
                                                <i class='bx bx-left-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                        <b-col>
                                            <vs-button
                                                danger
                                                gradient
                                                @click="push_to_exported(tr)"
                                                icon
                                            >
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </vs-button>
                                        </b-col>
                                    </b-row>
                                </vs-td>
                                <vs-td>
                                    <strong>{{ tr.employee }}</strong>
                                </vs-td>
                            </vs-tr>
                            </template>
                        </vs-table>
                    </b-col>
                <hr>
                <vs-pagination v-model="currentPage" :length="totalPages"></vs-pagination>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import fsg from "@/api/base";
// import Products from '../../../components/Products.vue';
import Products from "@/components/Products";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
import XLSX from 'xlsx';
import Product from "@/components/Product";
export default {
  name: "redesign",
  components: {
    Products,
    vue2Dropzone
  },
    data: () => ({
        product: '',
        iaCode: 'hehe',
        currentPage: 1,
        itemsPerPage: 60,
        brandName: '',
        price: '',
        data: [],
        manufacturer: '',
        external_product_id: '',
        external_product_id_type: '',
        condition_type: '',
        fulfillment_latency: '',
        feed_product_type: '',
        item_type: '',
        merchant_shipping_group_name: '',
        recommended_browse_nodes: '',
        search: '',
        images: [],
        all: [],
        filter_product: 'poster',
        data: [],
        edit_note: '',
        exported: false,
        viewContent: false,
        exporting: false,
        header: [],
        editing: false,
        selected_employee: '',
        selected_template: '',
        check_all: false,
        scopes: ['mod', 'admin'],
        upload_content: false,
        asin: '',
        employees: [],
        editActive: false,
        editProp: '',
        upload_ppc: false,
        asins: [],
        ads: [],
        delete_confirm: false,
        edit: Object,
        downloading: false,
        allow_dropzone: false,
        upload: false,
        dropzonePPC: {
            url: `https://v2.fecom.io/spam_ppc`,
            // url: `http://0.0.0.0:5001/spam_ppc`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            maxFiles: 1,
        },
        dropzoneOptions: {
            url: `https://v2.fecom.io/designs`,
            // url: `http://0.0.0.0:5001/designs`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'image/*',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
        },
        dropzonecontent: {
            url: `https://v2.fecom.io/redesign/upload_content`,
            // url: `http://0.0.0.0:5001/spam`,
            thumbnailWidth: 150,
            maxFilesize: 50,
            acceptedFiles: 'text/csv',
            headers: {
                "fsg-token": localStorage.getItem("fsg-token"),
                "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            maxFiles: 1,
        },

    }),
    computed: {
        totalPages() {
            return Math.ceil(this.asins.length / this.itemsPerPage);
        },
        paginatedAsins() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            var results = []
            var foo = this.asins
            return foo.slice(start, end)
        },
    },
    methods: {
        selectedProduct: function (val) {
        this.product = val
        },
        updatePagination(page) {
            this.currentPage = page;
        },
        vdropzone_success: function(file, resp){
            this.data = resp.data
            this.download()
        },
        download: function () {
            const data = XLSX.utils.aoa_to_sheet(this.data)
            const wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, data, 'PPC')
            XLSX.writeFile(wb, 'ppc.xlsx')
        },
        push_to_redesign: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_redesign=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        push_to_exported: function(tr){
            fsg.put(`redesign?asin=${tr.asin}&push_to_exported=true`)
                .then(r => {
                    this.$vs.notification({
                        title: "Pushed to redesign"
                    })
                })
        },
        search_asin: function(){
            fsg.get(`redesign?asin=${this.search}`)
                .then(r => {this.asins = r.data.msg})
        },
        download_image: function(url, fn) {
            fetch(url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = fn;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        download_mockup: function() {
            this.exporting = true
            if (this.selected_template == 'ornamets'){
                let selected = this.asins.filter((as) => as.selected == true)
                var data = []
                selected.forEach(as => data.push(as.asin))
                fsg.post('/ornament_redesign?only_image=true', {'asins': data, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false, alert(`${this.images}`)})
            }else{
                fsg.post('/poster_redesign?only_image=true', {'asins': this.asins, 'selected_template': this.selected_template})
                    .then(r => {this.images = r.data, this.exporting = false})
            }
        },
        download: function (){
            const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
            XLSX.writeFile(wb, `${this.selected_template}.xlsm`)
            this.data = []
        },
        export_csv: function(){
        //     if (['vertical_poster', 'square_poster'].includes(this.selected_template)) {
        //         fsg.post('/spam', {'asins': this.asins, 'selected_template': this.selected_template})
        //             .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false, this.download()})
        //     } else if (this.selected_template == 'phonecase_spam') {
        //         fsg.post('/phonecase_spam', {'asins': this.asins, 'selected_template': this.selected_template})
    //             .then(r => {this.data = r.data.msg, this.header = r.data.header, this.ads = r.data.ads, this.exporting = false, this.download()})
        //    // }
            var asins = []
            asins = this.asins.filter(asin => asin.selected)
            fsg.post('/exported_product', asins)
            var results = []

            // asins.forEach(e => results.push(e.mockups))
            let content = ''
            asins.forEach((asin) => {
                if (!asin.mockups){
                return
                }
                content += asin.mockups.join(",")
                content += "\n"
            })
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
            anchor.target = '_blank';
            anchor.download = 'result.csv';
            anchor.click();
        },
        upload_metadata: function(){
            this.upload_content = !this.upload_content
        },
        update_product: function(){
            fsg.put('/product', this.edit)
                .then(r => {
                    this.$vs.notification({
                        title: "updated",
                        color: "success"
                    })
                })
        },
        add_asin: function(){
            this.downloading = true
            fsg.post(`/redesign?asin=${this.asin}`)
                .then(r => {
                    this.asins.unshift.apply(this.asins, r.data.msg),
                    this.downloading = false
                    this.upload = false
                })
                .catch(error => {
                    this.$vs.notification({
                        title: "Something went wront!",
                        color: "danger"
                    })
                    this.downloading = false
                })
        },
        update_dropzone: function (val){
            this.dropzoneOptions.url = `https://v2.fecom.io/designs?type=${val}`
            // this.dropzoneOptions.url = `http://0.0.0.0:5001/designs?type=${val}`
            this.allow_dropzone = !this.allow_dropzone
        },
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })
        },
        delete_asin: function(){
            fsg.delete(`/redesign?asin=${this.edit.asin}`)
                .then(r => {
                    this.delete_confirm = !this.delete_confirm
                    this.$vs.notification({
                        title: 'Deleted',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Failed',
                        color: 'danger'
                    })

                })
        },
        update_note: function(){
            this.edit.note = this.edit_note
            fsg.put(`/redesign?asin=${this.edit.asin}&note=${this.edit.note}`)
                .then(r => {
                    this.$vs.notification({
                        title: 'Note updated',
                        color: 'success'
                    })
                })
                .catch(err => {
                    this.$vs.notification({
                        title: 'Note update failed',
                        color: 'success'
                    })
                })
        },
        select_product: function (val){
        this.product = val
        if(this.product == "alls"){
            this.asins = this.alls
        }else{
            this.asins = this.alls.filter(o => o.type == val)

        }
        },
    },
    beforeDestroy() {
        this.sockets.unsubscribe('confirm_design')
    },
    created() {
        fsg.get('/spam')
            .then(r => {this.asins = r.data.msg, this.alls = this.asins})
        fsg.get('/employees?team=content')
            .then(r => {this.employees = r.data.message})
    },
    watch: {
        filter_product: function() {
        },
        check_all: function(){
            this.asins.forEach(e => e.selected = !e.selected)
        },
        // data: function(){
        //     this.download()
        // },
        images: function(){
            this.images.forEach(obj => this.download_image(obj.url, obj.name))
        },
        selected_template: function () {
            let selected = this.asins.filter((e) => e.selected == true)
            let data = []
            selected.forEach(e => data.push(e.asin))
            if (data.length > 0) {
                fsg.put("/update_template_poster", {'asins': data, 'template': this.selected_template})
                    .then(r => this.$vs.notification({
                        title: "Updated"
                    }))
            } else if (this.selected_template.includes('phonecase')){
                this.asins = this.asins.filter(e => e.type == 'phone-case')
            } else if (this.selected_template.includes('poster')) {
                this.asins = this.asins.filter(e => e.type == 'poster')
            } else if (this.selected_template.includes('mug')) {
                this.asins = this.asins.filter(e => e.type == 'mug')
            }
        },
        selected_employee: function(){
            if (this.selected_employee == 'all'){
                fsg.get('/redesign?exported=oke')
                    .then(r => {this.asins = r.data.msg})
            }
            let selected = this.asins.filter((e) => e.selected == true)
            let data = []
            selected.forEach(e => data.push(e.asin))
            if (!data.length == 0){
                fsg.put(`/redesign?action=update_employee&content=true`, {'asins': data, 'employee': this.selected_employee})
                    .then(r => {
                        this.$vs.notification({
                            title: 'Updated employee',
                            color: 'success'
                        })
                    })
            }else {
                this.asins = this.asins.filter((e) => e.employee == this.selected_employee)
            }
        },
    }
}
</script>
<style scoped>

</style>
<style lang="scss" scope>
.fixed-header{
  position: fixed;
  top: 0;
  padding-bottom: 100px;
}
.vs-table__tr .vs-table__td {
  background: white;
  border: 1px solid #ddd;
  border-left: none;
  align-content: center;
}
.vs-table__tr .vs-table__td:last-child {
  width: 5%;
  border-right: none;
}
.vs-table__td:first-child {
    border-radius: 15px 0px 0px 15px;
    width: 10%;
}
.vs-table__td:last-child {
  width: 5%;
  border-radius: 15px 0px 0px 15px;
}
#dropzone {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 500px;
  padding: 40px;
  border: 1px;
  border-style: dashed;
  border-radius: 10px;
  overflow: scroll;
}
.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
