<template>
  <b-card no-body class="card-profile" alt="Image placeholder" img-top>
    <vs-dialog not-center width="600px" v-model="upload">
      <b-container fluid="">
        <h3 align="center">Upload avarta</h3>
        <p style="color: darkred">Chú ý upload tối đa 1 lượt 20 file, file dưới 50Mb</p>
        <hr size="3">
        <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
      </b-container>
    </vs-dialog>
    <b-row class="justify-content-center">
      <b-col lg="3" class="order-lg-2">
        <div class="card-profile-image">
          <!-- <a href="#">
            <b-img src="img/theme/team-4.jpg" rounded="circle" />
          </a> -->
          <b-img @click="upload =true" :src="user.avarta" rounded="circle" />
        </div>
      </b-col>
    </b-row>

    <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between">
        <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>
        <a href="#" class="btn btn-sm btn-default float-right">Message</a>
      </div>
    </b-card-header>

    <b-card-body class="pt-0">
      <b-row>
        <b-col >
          <div class="card-profile-stats d-flex justify-content-center mt-md-5">
            <!-- <div>
              <span class="heading">22</span>
              <span class="description">Friends</span>
            </div>
            <div>
              <span class="heading">10</span>
              <span class="description">Photos</span>
            </div>
            <div>
              <span class="heading">89</span>
              <span class="description">Comments</span>
            </div> -->
          </div>
        </b-col>
      </b-row>
      <div class="text-center">
        <h5 class="h3">
          {{ user.name }}<span class="font-weight-light"></span>
        </h5>
        <div class="h5 mt-4">
          <i class="ni location_pin mr-2"></i>{{ user.country }}
        </div>
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>{{ user.birthday }}
        </div>
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>{{ user.relationship }}
        </div>
        <div>
          <i class="ni education_hat mr-2"></i>{{ user.university }}
        </div>
        <hr class="my-4">
        <p>{{ user.about_me }}</p>
        <a href="#">Show more</a>

      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import fsg from "@/api/base";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
export default {
  components: {
    vue2Dropzone,
  },
  data() {
    return {
      upload: false,
      dropzoneOptions: {
        // url: `http://0.0.0.0:5001/userinfo/${localStorage.getItem("id")}`,
        url: `https://v2.fecom.io/userinfo/${localStorage.getItem("id")}`,
        thumbnailWidth: 150,
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        headers: {
          "fsg-token": localStorage.getItem("fsg-token"),
          "Access-Control-Allow-Origin": "*",
        },
        dictCancelUpload: false,
        dictUploadCanceled: false,
        dictInvalidFileType: false,
        maxFiles: 1,
      },
      user: {
      }
    };
  },
  methods: {
    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    },
  },
  created() {
    fsg.get(`/userinfo/${localStorage.getItem("id")}`)
      .then(r => {
        this.user = r.data
      })
  }
};
</script>
<style></style>
