<template>
    <b-container fluid>
        <b-card>
            <div>
                <vs-select placeholder="Square or Vertical" v-model="type">
                    <vs-option label="Standard" value="standard">
                        Standard
                    </vs-option>
                    <vs-option label="Square" value="square">
                        Square
                    </vs-option>
                    <vs-option label="Vertical" value="vertical">
                        Vertical
                    </vs-option>
                </vs-select>
                <hr>
            </div>
            <b-row>
                <b-col xl="3">
                    <vs-input icon-after v-model="brand_name" placeholder="brand name">
                        <template #icon>
                        <i class='bx bx-store'></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="3">
                    <vs-input icon-after v-model="standard_price" placeholder="standard price">
                        <template #icon>
                        <i class='bx bx-money'></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="3">
                    <vs-input icon-after v-model="merchant_shipping_group_name" placeholder="merchant shipping group name">
                        <template #icon>
                        <i class='bx bxs-ship' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="3">
                    <vs-input icon-after v-model="product_description" placeholder="product description">
                        <template #icon>
                        <i class='bx bxl-product-hunt' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <hr>
                <b-col xl="2">
                    <vs-input icon-after v-model="bullet_point1" placeholder="bullet point 1">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-input icon-after v-model="bullet_point2" placeholder="bullet point 2">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-input icon-after v-model="bullet_point3" placeholder="bullet point 3">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-input icon-after v-model="bullet_point4" placeholder="bullet point 4">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-input icon-after v-model="bullet_point5" placeholder="bullet point 5">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-input icon-after v-model="generic_keywords" placeholder="generic keywords">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <hr>
                <b-col xl="2">
                    <vs-input icon-after v-model="item_name" placeholder="item name">
                        <template #icon>
                        <i class='bx bxs-quote-alt-left' ></i>
                        </template>
                    </vs-input>
                </b-col>
                <b-col xl="2">
                    <vs-select placeholder="theme" v-model="theme">
                        <vs-option label="Nature" value="Nature">
                            Nature
                        </vs-option>
                        <vs-option label="Horror" value="Horror">
                            Horror
                        </vs-option>
                        <vs-option label="Fairytale" value="Fairytale">
                            Fairytale
                        </vs-option>
                        <vs-option label="Abstract" value="Abstract">
                            Abstract
                        </vs-option>
                        <vs-option label="Birds" value="Birds">
                            Birds
                        </vs-option>
                        <vs-option label="Floral" value="Floral">
                            Floral
                        </vs-option>
                        <vs-option label="Science Fiction" value="Science Fiction">
                            Science Fiction
                        </vs-option>
                        <vs-option label="Vintage" value="Vintage">
                            Vintage
                        </vs-option>
                        <vs-option label="Animals" value="Animals">
                            Animals
                        </vs-option>
                        <vs-option label="Maps" value="Maps">
                            Maps
                        </vs-option>
                        <vs-option label="Alphabets" value="Alphabets">
                            Alphabets
                        </vs-option>
                        <vs-option label="Religious" value="Religious">
                            Religious
                        </vs-option>
                        <vs-option label="Funny" value="Funny">
                            Funny
                        </vs-option>
                        <vs-option label="Movies" value="Movies">
                            Movies
                        </vs-option>
                        <vs-option label="Sports" value="Sports">
                            Sports
                        </vs-option>
                    </vs-select>
                </b-col>
            </b-row>
            <hr>
            <vs-button v-if="data"
                warning
                flat
                @click="download"
            >
                Success
            </vs-button>
            <vue2Dropzone @vdropzone-sending="sendingEvent" @vdropzone-error="vdropzone_error" @vdropzone-success="vdropzone_success" class="upload" ref="myVueDropzone" id="file_upload" :options="options"></vue2Dropzone>
        </b-card>
    </b-container>
</template>
<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import XLSX from 'xlsx';
export default {
    name: "fesogo",
    components: {
        vue2Dropzone,
    },
    data: () => ({
        type: '',
        data: null,
        theme: '',
        header: [],
        brand_name: "",
        standard_price: "",
        merchant_shipping_group_name: "Template PT",
        product_description: "",
        bullet_point1: "",
        bullet_point2: "",
        bullet_point3: "",
        bullet_point4: "",
        bullet_point5: "",
        generic_keywords: "",
        item_name: "",
        options: {
            url: 'https://v2.fecom.io/poster',
            // url: 'http://0.0.0.0:5001/poster',
            thumbnailWidth: 100,
            maxFilesize: 100,
            acceptedFiles: '*',
            headers: {
            "fsg-token": localStorage.getItem("fsg-token"),
            "Access-Control-Allow-Origin": "*",
            },
            dictCancelUpload: false,
            dictUploadCanceled: false,
            dictInvalidFileType: false,
            uploadMultiple: true,
            parallelUploads: 10000,
            dictDefaultMessage: 'Drop file here',
        },
    }),
    methods: {
        download: function (){
        const hello = XLSX.utils.json_to_sheet(this.data, {header: this.header})
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, hello, 'Sheet')
        XLSX.writeFile(wb, 'poster.xlsx')
        this.data = null
        },
        vdropzone_error: function(file){
            this.$vs.notification({
                title: `Upload file ${file.name}`
            })
        },
        vdropzone_success: function(file, resp){
            this.data = resp.msg,
            this.header = resp.header
        },
        sendingEvent: function(file, xhr, formData){
            formData.append('brand_name', this.brand_name)
            formData.append('standard_price', this.standard_price)
            formData.append('item_name', this.item_name)
            formData.append('manufacturer', this.manufacturer)
            formData.append('merchant_shipping_group_name', this.merchant_shipping_group_name)
            formData.append('product_description', this.product_description)
            formData.append('bullet_point1', this.bullet_point1)
            formData.append('bullet_point2', this.bullet_point2)
            formData.append('bullet_point3', this.bullet_point3)
            formData.append('bullet_point4', this.bullet_point4)
            formData.append('bullet_point5', this.bullet_point5)
            formData.append('generic_keywords', this.generic_keywords)
            formData.append('theme', this.theme)
            formData.append('type', this.type)
        }
    },
    watch: {
        data: function(){

        }
    }
}
</script>
<style lang="scss" scoped>
.upload {
  background-color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  height: 600px;
  padding: 10px;
  border: 1px;
  /* margin: 30px; */
  border-style: dashed;
  border-radius: 10px;
  overflow: hidden;
  min-width: 100% !important;
}

</style>
