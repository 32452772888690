<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-success" style="min-height: 50px; background-image: url(img/theme/redbubble.jpg); background-size: cover; background-position: center top; background-color: #2dce89">
            <h1>Total {{ total }}</h1>
            <div>
                <b-dropdown id="product" :text="product" class="m-md-2">
                    <b-dropdown-item @click="product = p" v-for="(p, index) in products" :key="index">{{ p }}</b-dropdown-item>
                </b-dropdown>
            </div>
        </base-header>
        <modal
            :show.sync="modals.upload"
            size="lg"
            body-classes="p-0"
        >
            <b-card
                type="secondary"
                header-classes="bg-transperent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    Upload images
                </template>
                <template>
                    <vue2Dropzone id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
                </template>
            </b-card>
            <template slot="footer">
                <h3></h3>
            </template>
        </modal>
        <modal
            :show.sync="modals.detail"
            body-classes="p-0"
            size="xl"
        >
            <b-card
                type="secondary"
                header-classes="bg-transperent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template slot="header">
                    Description
                </template>
                <template>
                    <base-input
                        v-model="edit.note"
                        class="font-weight-600 name mb-0 text-sm"
                        placeholder="Note"
                        prepend-icon="bx bx-rename">
                    </base-input>
                    <hr>
                    <base-input
                        v-model="edit.title"
                        class="font-weight-600 name mb-0 text-sm"
                        placeholder="title"
                        prepend-icon="bx bx-rename">
                    </base-input>
                    <hr>
                    <b-form-textarea
                        v-model="edit.Description"
                        placeholder="Enter description..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.keyword"
                        placeholder="Enter keyword..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.bullet_point1"
                        placeholder="Enter bullet_point1..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.bullet_point2"
                        placeholder="Enter bullet_point2..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.bullet_point3"
                        placeholder="Enter bullet_point3..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.bullet_point4"
                        placeholder="Enter bullet_point4..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.bullet_point5"
                        placeholder="Enter bullet_point5..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.kw_ppc_1"
                        placeholder="Enter kw_ppc_1..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.kw_ppc_2"
                        placeholder="Enter kw_ppc_2..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.kw_ppc_3"
                        placeholder="Enter kw_ppc_3..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.kw_ppc_4"
                        placeholder="Enter kw_ppc_4..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                    <hr>
                    <b-form-textarea
                        v-model="edit.kw_ppc_5"
                        placeholder="Enter kw_ppc_5..."
                        rows="4"
                        max-rows="100"
                        class="font-weight-600 name mb-0 text-sm"
                    >
                    </b-form-textarea>
                </template>
            </b-card>
            <template slot="footer">
                <b-button @click="update_product">cancel</b-button> -->
            </template>
        </modal>
        <b-container fluid class="mt--7">
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-form-checkbox v-model="exported" name="exported" switch>
                    </b-form-checkbox>
                    <b-button loading class="btn-warning" variant="primary" @click="exporting = true">Export</b-button>
                    <b-button variant="primary" @click="modals.upload = true" >upload csv</b-button>
                    <b-dropdown id="employee" text="Employee" class="m-md-2">
                        <b-dropdown-item @click="employee = e.name" v-for="(e, index) in employees" :key="index">{{ e.name }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown id="product" text="Template" class="m-md-2">
                        <b-dropdown-item v-if="product == 'FESOGO-PT'">Ngang</b-dropdown-item>
                        <b-dropdown-item v-if="product == 'FESOGO-PT'">Doc</b-dropdown-item>
                        <b-dropdown-item v-if="product == 'FESOGO-TS'">Default</b-dropdown-item>
                        <b-dropdown-item v-if="product == 'FESOGO-MG'">Default</b-dropdown-item>
                    </b-dropdown>
                </b-card-header>

                <el-table class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="designs">
                    <el-table-column
                        width="60px"
                        prop="selected"
                    >
                        <template v-slot="{row}">
                            <b-form-checkbox v-model="row.selected" size="lg"></b-form-checkbox>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="created"
                                    prop="_created"
                                    width="150px"
                                    sortable>
                        <template v-slot="{row}">
                            <span class="font-weight-600 name mb-0 text-sm">{{ row._created }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="SKU"
                                    prop="fsg_sku"
                                    min-width="200px"
                                    sortable>
                        <template v-slot="{row}">
                            <span @click="doCopy(row.fsg_sku)" class="font-weight-600 name mb-0 text-sm">{{ row.fsg_sku }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="title"
                                    min-width="200px"
                                    prop="name"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <!-- <a href="#" class="avatar rounded-circle mr-3">
                                    <img alt="Image placeholder" src="https://ih1.redbubble.net/image.819803303.4430/throwpillow,small,1000x-bg,f8f8f8-c,0,200,1000,1000.jpg">
                                </a> -->
                                <b-media-body>
                                    <span class="font-weight-600 name mb-0 text-sm">{{ row.title }}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>
                    <el-table-column label="Description"
                                    prop="Description"
                                    min-width="200px"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span @click="modals.detail = true, edit=row" class="font-weight-600 name mb-0 text-sm truncate">{{ row.Description }}</span>
                                </b-media-body>
                            </b-media>
                        </template>

                    </el-table-column>
                    <el-table-column label="Note"
                                    prop="note"
                                    min-width="200px"
                                    sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span @click="modals.detail = true, edit=row" class="font-weight-600 name mb-0 text-sm truncate">{{ row.note }}</span>
                                </b-media-body>
                            </b-media>
                        </template>

                    </el-table-column>

                    <el-table-column label="Users" width="100px">
                        <template v-slot="{row}">
                            <div class="avatar-group">
                                <!-- <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                                data-original-title="Ryan Tompson">
                                    <img alt="Image placeholder" src="img/theme/team-1.jpg">
                                </a> -->
                                <span class="font-weight-600 name mb-0 text-sm truncate text-danger">{{ row.employee }}</span>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Image"
                                    prop="thumbnail_url"
                                    min-width="100px">
                        <template v-slot="{row}">
                            <div class="d-flex align-items-center">
                                <a :href="`#/designs?sku=${row.fsg_sku}`" target="_blank">
                                    <img :src="row.thumbnail_url" alt="" style="width: 100px; height: auto">
                                </a>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="60px">
                        <template v-slot="{row}">
                            <el-dropdown trigger="click" class="dropdown">
                            <span class="btn btn-sm btn-icon-only text-light">
                            <i class="fas fa-ellipsis-v mt-2"></i>
                            </span>
                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                    <b-dropdown-item @click="modals.detail = true, edit = row">edit</b-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <b-card-footer class="py-4 d-flex justify-content-end">
                    <base-pagination v-model="currentPage" :per-page="1" :total="Math.floor(design.length/1)"></base-pagination>
                </b-card-footer> -->
            </b-card>
        </b-container>

    </div>

</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import fsg from "@/api/base";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone'
export default {
    data(){
        return {
            total: '',
            product: 'FESOGO-TS',
            products: [],
            employee: '',
            employees: [],
            designs: [],
            modals: {
                detail: false,
                upload: false
            },
            exported: false,
            exporting: false,
            edit: Object,
            dropzoneOptions: {
                url: `https://v2.fecom.io/redesign/upload_content`,
                // url: `http://0.0.0.0:5001/redesign/upload_content`,
                thumbnailWidth: 150,
                maxFilesize: 50,
                acceptedFiles: 'text/csv',
                headers: {
                    "fsg-token": localStorage.getItem("fsg-token"),
                    "Access-Control-Allow-Origin": "*",
                },
                dictCancelUpload: true,
                dictUploadCanceled: true,
                dictInvalidFileType: true,
                maxFiles: 1,
            },
        }
    },
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        vue2Dropzone,
    },
    created: function(){
        fsg.get(`/fsg-contents?product=FESOGO-TS`)
            .then(r => {this.designs = r.data.msg, this.total = r.data.total})
        fsg.get('/fesogo_products')
            .then(r => {this.products = r.data.msg})
        fsg.get('/employees?role=designer')
            .then(r => {this.employees = r.data.message})
    },
    watch: {
        product: function(){
            this.dropzoneOptions.url = `https://v2.fecom.io/designs?type=${this.product}&by_fsg=oke`
            fsg.get(`/fsg-designs?product=${this.product}`)
                .then(r => {this.designs = r.data.msg})
        },
        employee: function(){
            var selected = []
            this.designs.forEach(e => {
                if(e.selected){
                    selected.push(e.fsg_sku)
                }
            })
            fsg.put(`/fsg-designs?action=assign`, {'name': this.employee, 'skus': selected})
                .then(r => {this.$vs.notification({
                    title: 'Assigned',
                    color: 'success'
                })})
            this.employee = ''
        },
        exporting: function(){
            this.$vs.notification({
                title: "Chờ làm xong cái web design cho toàn bộ các sản phẩm",
                color: "danger"
            })
        },
        exported: function(){
            if (this.exported) {
                fsg.get(`/fsg-contents?product=FESOGO-TS&exported=oke`)
                    .then(r => {this.designs = r.data.msg, this.total = r.data.total})
            }else {
                fsg.get(`/fsg-contents?product=FESOGO-TS`)
                    .then(r => {this.designs = r.data.msg, this.total = r.data.total})
            }
        }
    },
    methods: {
        doCopy: function (val){
        this.$copyText(val)
        this.$vs.notification({
            title: `Copied ${val}`,
            color: "success",
        })},
        update_product: function(){
            fsg.put('/update_product', this.edit)
                .then(r => {
                    this.$vs.notification({
                        title: "updated",
                        color: "success"
                    })
                })
        },
    }

}
</script>
