<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h1>Author TTS</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="confirmAuthorTts">Confirm Author TTS</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  methods: {
    confirmAuthorTts() {
      axios.get(`/auth-tts?seller_id=${this.$route.params.id}&app_key=${this.$route.params.app_key}&auth_code=${this.$route.params.code}`)
      // Add your logic here
    }
  }
}
</script>
